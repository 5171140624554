import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AuthWrapper from "./AuthWrapper/AuthWrapper";
import { ModelProvider } from "./ContextProvider";
import { WalletProvider } from "./context/WalletContext";
import Docs from "./pages/Docs";
import Home from "./pages/Home";
import Model from "./pages/Model";
import Strategy from "./pages/Strategy";
import { ErrorProvider } from "./context/ErrorContext";

function AppRoutes() {
  return (
    <ErrorProvider>
      <WalletProvider>
        <ModelProvider>
          <Router>
            <AuthWrapper>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/model" element={<Model />} />
                {/* <Route path='/' element={<Api />} /> */}
                <Route path="/docs" element={<Docs />} />
                <Route path="/strategy" element={<Strategy />} />
                {/* Catch-all route */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </AuthWrapper>
          </Router>
        </ModelProvider>
      </WalletProvider>
    </ErrorProvider>
  );
}

export default AppRoutes;
