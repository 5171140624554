import React, { useState, useEffect, useCallback, useContext } from 'react';
import styles from './GeneralOverview.module.css';
import { useModelContext } from '../../ContextProvider';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../Tooltip/Tooltip';
import { BASE_URL, PH_GENERAL_OVERVIEW } from '../../constants/constants';
import axiosInstance from '../../utils/axiosInstance';
import { useDisconnect } from '@web3modal/ethers/react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import {extractProjectIds} from "../../utils/helpers"
import { useMachine } from '@xstate/react';
import { assign } from 'xstate';
import { authMachine } from '../../xstate/authXstate';


function GeneralOverview({ dashboardState }) {
  const [projectIds, setProjectIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { setModel, setProjectID } = useModelContext();
  const [data, setData] = useState(PH_GENERAL_OVERVIEW);
 

  // const { disconnect } = useDisconnect();
  const { state:{context}, send } = useContext(AuthContext);

  // const [state, send] = useMachine(
  //   authMachine.provide({
     
  //     actions: {
  //       formatOverviewData: 
  //     },
  //   })
  // );

  // useEffect(() => {
  //   // const initialData = Array.from({ length: 9 }, (_, i) => ({
  //   //   label: '--',
  //   //   value: '0%',
  //   //   trend: 'N/A',
  //   // }));
  //   setData(PH_GENERAL_OVERVIEW);
  // }, []);
  useEffect(() => {
    if (dashboardState) {
      const uniqueProjectIds = extractProjectIds(dashboardState);
      setProjectIds(uniqueProjectIds);
      setSelectedOption(selectedOption ? selectedOption :uniqueProjectIds[0])
    } else {
      setSelectedOption(null);
      setData(PH_GENERAL_OVERVIEW);
    }
  }, [context]);

  useEffect(() => {
    const fetchData = async () => {
        const formattedData = Object.keys(context.modelState || {}).map((label) => {
          const residual = context.modelState[label][selectedOption]?.residual;
          return {
            label,
            value: residual !== undefined ? `${residual}%` : "0%",
            trend:
              residual !== undefined ? (residual > 0 ? "up" : "down") : "N/A",
          };
        });

        setData(formattedData.slice(0, 9));
      // } catch (error) {
      //   if (error.response && error.response.status === 401) {
      //     send({ type: "SIGN_OUT" });
      //     localStorage.removeItem("authState");
      //     disconnect();
      //   } else {
      //     console.error("Error fetching data:", error);
      //   }
      // }
    // };
      }

      if (selectedOption &&  context?.modelState && Object.keys(context.modelState).length > 0) {
        fetchData();
      }


  }, [selectedOption,context]); 


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const selectOption = (projectId) => {
    setSelectedOption(projectId);
    send({type:"UPDATE_MODEL_STATE"})
    setIsOpen(false);
  };
  const navigate = useNavigate();
  const handleCardClick = (model) => {
    setModel(model);
    send({
      type:"UPDATE_PROJECT_ID",
      payload : { selectedProjectId :model}
    })
    setProjectID(selectedOption);
    navigate('/model');
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headingContainer}>
            <div className={styles.heading}>General Overview </div>
            <Tooltip id='general-overview' page='home-page' />
          </div>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleDropdown}>
              <span className={styles.selectedOption}>
                {/* <img src={`/images/${selectedOption.toLowerCase()}.svg`} alt={selectedOption} /> */}
                {selectedOption}
              </span>
              <span className={styles.arrow}>
                {isOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {context.projectIds.map((option) => (
                  <div key={option} className={styles.dropdownItem} onClick={() => selectOption(option)}>
                    {/* <img src={`/images/${option.toLowerCase()}.svg`} alt={option} /> */}
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.cardContainer}>
          {data.map((item, index) => (
            <div
              key={index}
              className={styles.card}
              onClick={() => handleCardClick(item.label)}
            >
              {item.value !== "0%" && (
                <div className={styles.gradient}>
                  <img
                    src={
                      item.trend === "up"
                        ? "/images/cardBgGreen.png"
                        : "/images/cardBgRed.svg"
                    }
                    alt="card background"
                  />
                </div>
              )}
              <div className={styles.cardContentContainer}>
                <div className={styles.cardDetails}>
                  <div
                    className={
                      item.value === "0%"
                        ? styles.valueGrey
                        : item.trend === "up"
                        ? styles.valueGreen
                        : styles.valueRed
                    }
                    style={{
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {item.value}
                  </div>
                  <div className={styles.collectionName}>{item.label}</div>
                </div>
                {item.value !== "0%" && (
                  <div className={styles.graph}>
                    <img
                      src={
                        item.trend === "up"
                          ? "/images/graphGreen.svg"
                          : "/images/graphred.svg"
                      }
                      alt="graph"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default GeneralOverview;
