import React from 'react';
import withLayout from '../components/withLayout/withLayout';
import MdDocs from '../components/MdDocs/MdDocs';

const Docs = () => {
  return (
    <div style={{ color: 'white', width: '100%' }}>
      <MdDocs />
    </div>
  );
};

export default withLayout(Docs);
