import { useLocation, Link } from "react-router-dom";
import styles from "./Sidebar.module.css";
import WalletButtons from "../WalletButtons/WalletButtons";
import { useEffect } from "react";
import Ads from "../Ads/Ads";

const menuItems = [
  { src: "/images/homeIcon.svg", label: "Home", path: "/", status: true },
  {
    src: "/images/modelIcon.svg",
    label: "Model",
    path: "/model",
    status: true,
  },
  {
    src: "/images/strategyIcon.svg",
    label: "Strategy",
    path: "/strategy",
    status: true,
  },
  { src: "/images/docsIcon.svg", label: "Docs", path: "/docs", status: true },
  { src: "/images/apiIcon.svg", label: "API", path: "/api", status: false },
];

export default function Sidebar({ onClick }) {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const preloadImages = menuItems
      .filter((item) => item.status)
      .flatMap((item) => [item.src, item.src.replace(".svg", "S.svg")]);

    preloadImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div>
          <div className={styles.logo} onClick={onClick}>
            <img src="images/yaiLogo.svg" alt="Logo" />
          </div>
          <div className={styles.menu}>
            {menuItems.map((item) =>
              item.status ? (
                <Link
                  to={item.path}
                  key={item.label}
                  className={`${styles.menuItem} ${
                    currentPath === item.path ? styles.selected : ""
                  }`}
                >
                  <img
                    src={
                      currentPath === item.path
                        ? item.src.replace(".svg", "S.svg")
                        : item.src
                    }
                    alt={item.label}
                  />
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>{item.label}</div>
                  </div>
                </Link>
              ) : (
                <div
                  key={item.label}
                  className={`${styles.menuItem} ${styles.menuItemDisabled}`}
                >
                  <img src={item.src} alt={item.label} />
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>
                      {item.label}{" "}
                      <span className={styles.comingSoon}>Coming Soon</span>
                    </div>
                  </div>
                </div>
              )
            )}
            <Ads />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomInner}>
            <WalletButtons />
          </div>
        </div>
      </div>
    </div>
  );
}
