import { useDisconnect, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { React, useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthWrapper/AuthWrapper.js";
import GeneralOverview from "../components/GeneralOverview/GeneralOverview";
import withAuthenticationGuard from "../components/guards/withAuthWrapper";
import withBalanceRestriction from "../components/guards/withBalanceRestriction";
import StateHeatmap from "../components/Heatmap/StateHeatmap";
import MarketOverview from "../components/MarketOverview/MarketOverview";
import Technicals from "../components/Technicals/Technicals";
import withLayout from "../components/withLayout/withLayout";
import { MinTokenAmount } from "../constants/constants";
import { useWalletContext } from "../context/WalletContext.js";
import axiosInstance from "../utils/axiosInstance";

const Home = (props) => {
  // const [dashboardState, setDashboardState] = useState();
  const { tokenBalance } = useWalletContext();
  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  // const { disconnect } = useDisconnect();
  // const authState = JSON.parse(localStorage.getItem("authState"));

  // useEffect(() => {
  //     const fetchDashboardState = async () => {
  //       try {
  //         if (authState && authState?.accessToken) {
  //           const response = await axiosInstance.get("/dashboard_state");
  //           const result = response.data;
  //           setDashboardState(result);
  //         }
  //       } catch (error) {
  //         if (error.response && error.response.status === 401) {
  //           send({ type: "SIGN_OUT" });
  //           localStorage.removeItem("authState");
  //           localStorage.setItem("shouldLogout", true);
  //           disconnect();
  //         } else {
  //           console.error("An error occurred:", error);
  //         }
  //       }
  //     };
  //     fetchDashboardState();
  //   }, [state]);


  const renderComponents = (data) => (
    <>
      <GeneralOverview dashboardState={data} />
      <Technicals dashboardState={data} />
      <MarketOverview dashboardState={data} />
      <StateHeatmap dashboardState={data} />
    </>
  );

  return (
    <>
      {isConnected && tokenBalance >= MinTokenAmount ? (
        renderComponents(state.context.dashboardState)
      ) : isConnected && tokenBalance < MinTokenAmount ? (
        <>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backdropFilter: "blur(1px)",
              pointerEvents: "none",
              zIndex: "10",
            }}
          ></div>
          {renderComponents(null)}
        </>
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backdropFilter: "blur(1px)",
              pointerEvents: "none",
              zIndex: "10",
            }}
          ></div>
          {renderComponents(null)}
        </>
      )}
    </>
  );
};

export default withLayout(
  withAuthenticationGuard(Home)
);
