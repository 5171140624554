import React from 'react';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import ConnectModal from '../ConnectModal/ConnectModal';

const withAuthenticationGuard = (WrappedComponent) => {
  return function WithAuthenticationGuard(props) {
    const { isConnected } = useWeb3ModalAccount();

    if (!isConnected) {
      return (
        <>
          <ConnectModal />
          <WrappedComponent {...props} />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthenticationGuard;
