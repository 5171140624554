import { useDisconnect } from "@web3modal/ethers/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { AuthContext } from "../../AuthWrapper/AuthWrapper";
import { useErrorContext } from "../../context/ErrorContext";
import Loader from "../Loader/Loader";
import styles from "./MdDocs.module.css";

function MdDocs() {
  const [content, setContent] = useState("");
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [processedSections, setProcessedSections] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const { axiosInstance } = useErrorContext();

  useEffect(() => {
    const fetchDocs = async () => {
      try {
        setIsLoading(true); // Set loading to false when data is fetched

        const response = await axiosInstance.get("/dashboard_docs");
        const data = response.data;
        setContent(data.content);

        // Process sections and generate IDs
        const sections = data.content
          .split(/(?=^#\s)/m)
          .filter((section) => section.trim() !== "");

        const processed = sections.map((section) => {
          const lines = section.split("\n");
          const headings = lines
            .map((line) => {
              if (line.startsWith("#")) {
                const level = line.match(/^#+/)[0].length;
                const text = line.replace(/^#+\s+/, "");
                const id = text
                  .toLowerCase()
                  .replace(/\s+/g, "-")
                  .replace(/[()]/g, "");
                return { level, text, id };
              }
              return null;
            })
            .filter(Boolean);

          return { content: section, headings };
        });

        setProcessedSections(processed);
        setIsLoading(false);

        // Scroll to hash after content is set
        if (location.hash) {
          scrollToHash();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          send({ type: "SIGN_OUT" });
          localStorage.removeItem("authState");
          disconnect();
        }
        setError("Failed to load documentation");
        setIsLoading(false);
      }
    };

    fetchDocs();
  }, []);

  // Ensure activeTab is within bounds
  const validActiveTab = Math.max(
    0,
    Math.min(activeTab, processedSections.length - 1)
  );

  const renderers = {
    heading: ({ level, children }) => {
      const text = children[0];
      const id = text.toLowerCase().replace(/\s+/g, "-").replace(/[()]/g, "");
      return React.createElement("h" + level, { id }, children);
    },
    link: ({ href, children }) => <a href={href}>{children}</a>,
  };

  const scrollToHash = () => {
    setTimeout(() => {
      const hash = decodeURIComponent(location.hash.substring(1));
      const [page, section] = hash.split("?");

      if (page) {
        processedSections.forEach((section, index) => {
          if (section.headings.some((heading) => heading.id === page)) {
            setActiveTab(index);
          }
        });

        if (section) {
          const elements = document.querySelectorAll("h2");
          elements.forEach((element) => {
            if (
              element.textContent
                .toLowerCase()
                .replace(/\s+/g, "-")
                .replace(/[()]/g, "") === section
            ) {
              element.scrollIntoView({ behavior: "auto" });
            }
          });
        }
      }
    }, 100);
  };

  useEffect(() => {
    if (location.hash) {
      const hash = decodeURIComponent(location.hash.substring(1));
      const [page, section] = hash.split("?");

      // Find the section that contains the matching heading
      processedSections.forEach((section, index) => {
        if (section.headings.some((heading) => heading.id === page)) {
          setActiveTab(index); // Activate the correct tab
        }
      });
    }
  }, [location.hash, processedSections]);

  useEffect(() => {
    if (location.hash) {
      scrollToHash();
    }
  }, [validActiveTab]);

  const handleTabClick = (index, title) => {
    setActiveTab(index);

    const formattedTitle = title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[()]/g, "");

    navigate(`#${formattedTitle}`); 
  };

  if (error) {
    return (
      <div className={`${styles.mdDocs} ${styles.errorContainer}`}>
        <div className={styles.errorMessage}>{error}</div>
      </div>
    );
  }

  return (
    <div className={styles.mdDocs} ref={contentRef}>
      {isLoading ? ( 
        <Loader />
      ) : (
        <>
          <div className={styles.tabContainer}>
            {processedSections.map((section, index) => {
              const title = section.headings[0]?.text || `Section ${index + 1}`;
              return (
                <button
                  key={index}
                  className={`${styles.tab} ${
                    validActiveTab === index ? styles.activeTab : ""
                  }`}
                  onClick={() => handleTabClick(index, title)}
                >
                  {title}
                </button>
              );
            })}
          </div>

          <div className={styles.card}>
            <div className={styles.cardContent}>
              {processedSections.length > 0 && (
                <ReactMarkdown
                  components={{
                    ...renderers,
                    heading: ({ level, children }) => {
                      const text = children[0];
                      const id = text
                        .toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/[()]/g, "");
                      return React.createElement("h" + level, { id }, children);
                    },
                  }}
                  rehypePlugins={[rehypeRaw]}
                  className={styles.markdownContent}
                >
                  {processedSections[validActiveTab].content.replace(
                    /^#\s+.*\n/,
                    ""
                  )}
                </ReactMarkdown>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MdDocs;
