import React, { useState, useEffect, useContext } from 'react';
import styles from './Strategies.module.css';
import Tooltip from '../Tooltip/Tooltip';
import { BASE_URL, MARKET_OVERVIEW_INIT, STRATEGIES_MODEL } from '../../constants/constants';
import axiosInstance from '../../utils/axiosInstance';
import { useDisconnect } from '@web3modal/ethers/react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import { useErrorContext } from '../../context/ErrorContext';

function Strategies({ dashboardState, initialProjectId }) {
  const [data, setData] = useState(MARKET_OVERVIEW_INIT);
  const [projectIds, setProjectIds] = useState([]);
  // const [collectionNames, setCollectionNames] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(initialProjectId);
  const [sortConfig, setSortConfig] = useState({ key: 'winrate', direction: 'desc' });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [tableClass, setTableClass] = useState('');
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const { axiosInstance } = useErrorContext();
  
  useEffect(() => {
    if (dashboardState) {
      const extractProjectIds = (dashboardState) => {
        const projectIdsSet = new Set();
        Object.values(dashboardState).forEach((projectList) => {
          projectList.forEach((projectId) => projectIdsSet.add(projectId));
        });
        return Array.from(projectIdsSet);
      };

      const uniqueProjectIds = extractProjectIds(dashboardState);
      setProjectIds(uniqueProjectIds || []);

      if (uniqueProjectIds.length > 0) {
        setSelectedProjectId(initialProjectId || uniqueProjectIds[0]);
      }
    } else {
      // Reset state when dashboardState is null
      setProjectIds([]);
      setSelectedProjectId(null);
      setData(MARKET_OVERVIEW_INIT);
      setIsLoading(false);
    }
  }, [dashboardState, initialProjectId]);

  useEffect(() => {
    
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get('/strategy_state');
          const result = response.data;
          const formattedData = Object.entries(result)
            .filter(([_, data]) => data[selectedProjectId] && !data[selectedProjectId].error)
            ?.map(([collectionName, data]) => {
              const projectData = data[selectedProjectId];
              return {
                collectionName,
                sharpe: projectData.sharpe,
                sortino: projectData.sortino,
                winrate: projectData.winrate,
              };
            });
          //console.log(formattedData);
          sortData(sortConfig.key, sortConfig.direction, formattedData);
          if (formattedData.length > 5) {
            setTableClass(styles.largeTable);
          } else {
            setTableClass('');
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            send({ type: "SIGN_OUT" });
            localStorage.removeItem("authState");
            disconnect();
          }
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      };
    if (dashboardState && selectedProjectId) {
      fetchData();
    }
  }, [selectedProjectId, dashboardState]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    setIsOpen(false);
  };

  const sortData = (key, direction = 'desc', dataToSort = data) => {
    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const toggleSortDirection = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    sortData(key, direction);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <img src='/images/tableSortArrow.svg' alt='Sort ascending' className={styles.sortIcon} />
      ) : (
        <img src='/images/tableSortArrow.svg' alt='Sort descending' className={`${styles.sortIcon} ${styles.inverted}`} />
      );
    }
    return <img src='/images/tableSortArrow.svg' alt='Sort' className={`${styles.sortIcon} ${styles.inactive}`} />;
  };

  const columns = STRATEGIES_MODEL;

  const formatValue = (key, value) => {
    if (value === '--' || value === null || value === undefined) return '--';

    if (key === 'collectionName') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }

    let numValue = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numValue)) return 'N/A';

    switch (key) {
      case 'sharpe':
        return numValue.toFixed(4);
      case 'sortino':
        return numValue.toFixed(4);
      case 'winrate':
        return `${numValue.toFixed(2)}%`;
      default:
        return value;
    }
  };

  const getResidualClass = (residual) => {
    if (residual === '--') return '';
    const numResidual = parseFloat(residual);
    return !isNaN(numResidual) && numResidual >= 0 ? styles.positive : styles.negative;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Strategies</div>
            <Tooltip id="strategies" page="model-page" />
          </div>

          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleDropdown}>
              <span className={styles.selectedOption}>
                {/* <img src={`/images/${selectedProjectId?.toLowerCase()}.svg`} alt={selectedProjectId} /> */}
                {selectedProjectId}
              </span>
              <span className={styles.arrow}>
                {isOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isOpen && (
              <div className={styles.dropdownMenu}>
                {projectIds?.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectProjectId(projectId)}
                  >
                    {/* <img src={`/images/${projectId?.toLowerCase()}.svg`} alt={projectId} /> */}
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.tableContainer} ${tableClass}`}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map(({ key, label, sortable }) => (
                  <th
                    key={key}
                    onClick={
                      sortable ? () => toggleSortDirection(key) : undefined
                    }
                    aria-sort={
                      sortConfig.key === key ? sortConfig.direction : "none"
                    }
                  >
                    {label} {sortable && getSortIcon(key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index} className={styles.tableRow}>
                  {columns.map(({ key }) => (
                    <td
                      key={key}
                      className={
                        key === "residual" ? getResidualClass(item[key]) : ""
                      }
                    >
                      {formatValue(key, item[key])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Strategies;
