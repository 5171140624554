import { Fixed_Amount_Upto_Below_Threshold,Fixed_Amount_Upto_Above_Threshold,Fixed_Amount_threshold } from "../constants/constants";

export const extractProjectIds = (dashboardState) => {
    const projectIdsSet = new Set();
    Object.values(dashboardState).forEach((projectList) => {
      projectList.forEach((projectId) => projectIdsSet.add(projectId));
    });
    return Array.from(projectIdsSet);
  }


  export const fixToTwoDecimals = (value, digits, isPercentage = false) => {
    if (isNaN(value)) value = 0;

    let decimalPlaces;
    if (isPercentage) {
      // If it's a percentage and below 10, use 2 decimal places; otherwise, use digits if provided
      decimalPlaces = (value < 10) ? 2 : (digits !== undefined ? digits : 2);
    } else {
      // If it's a regular value and below 10, use 3 decimal places; otherwise, use digits if provided
      decimalPlaces = (value < 10) ? 3 : (digits !== undefined ? digits : 3);
    }

    return parseFloat(value).toFixed(decimalPlaces);
  };
