import { useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useMachine } from '@xstate/react';
import React, { createContext, useEffect } from 'react';
import { fromPromise } from 'xstate';
import { useSignMessage } from '../constants/getContracts';
import { authMachine } from '../xstate/authXstate';
import useIpLookup from './useIpLookup';
import { useErrorContext } from "../context/ErrorContext";

export const AuthContext = createContext(null);

const AuthWrapper = ({ children }) => {
  const { isConnected, address } = useWeb3ModalAccount();
  const ipAddress = useIpLookup()
  const signMessage = useSignMessage();
  const { disconnect } = useDisconnect();
  const { axiosInstance, clearError } = useErrorContext();
  
  const [state, send] = useMachine(
    authMachine.provide({
      actors: {
        fetchDashboardState: fromPromise(async ({ input }) => {
          const response = await axiosInstance.get("/dashboard_state");
          const result = response.data;
          return {
            result,
          };
        }),

        fetchModelsData: fromPromise(async ({ input: { context } }) => {
          const response = await axiosInstance.get("/model_state");
          const result = response.data;
          return { result };
        }),

        signInService: fromPromise(async ({ input }) => {
          try {
            const response = await axiosInstance.post("/login");
            const { access_token } = response.data;
            return {
              accessToken: access_token,
              ip_address: input.ip_address,
              wallet_address: input.wallet_address,
              signature: input.signature,
            };
          } catch (error) {
            throw new Error(error.response.data.detail);
          }
        }),
        logoutService: fromPromise(async ({ input }) => {
          try {
            const response = await axiosInstance.post("/logout");
          } catch (error) {
            throw new Error(error.response.data.detail);
          }
        }),
      },
      actions: {
        disconnectFromMetamask: async() => {
          await axiosInstance.post("/logout").then(()=>{
            disconnect();
            localStorage.removeItem("authState");
          })

          console.log("disconnectFromMetamask");
        },
        clearError: () => {
          clearError();
        },
      },
    })
  );


  useEffect(() => {
    const signInUser = async () => {
      const authState = JSON.parse(localStorage.getItem("authState"));
   

      if (
        !authState?.accessToken &&
        ipAddress &&
        isConnected
      ) {
        try {
          const { signature } = await signMessage();
          send({
            type: "SIGN_IN",
            payload: {
              ip_address: ipAddress,
              wallet_address: address,
              signature,
            },
          });
        } catch (error) {
          console.error("Error signing message:", error);
        }
        return
      } else if(isConnected && !authState?.accessToken){
          disconnect()
          localStorage.removeItem("authState")
    }
    };

    signInUser();
  }, [isConnected]);

  return <AuthContext.Provider value={{ state, send }}>{children}</AuthContext.Provider>;
};

export default AuthWrapper;