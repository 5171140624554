import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import React, { useCallback, useContext, useState } from 'react';
import { AuthContext } from '../AuthWrapper/AuthWrapper.js';
import withAuthenticationGuard from '../components/guards/withAuthWrapper';
// import withBalanceRestriction from '../components/guards/withBalanceRestriction';
import HistoricalOverview from '../components/HistoricalOverview/HistoricalOverview';
import StrategyOverview from '../components/StrategyOverview/StrategyOverview';
import Trades from '../components/Trades/Trades';
import withLayout from '../components/withLayout/withLayout';
// import { MinTokenAmount } from '../constants/constants';
// import { useWalletContext } from '../context/WalletContext';
// import axiosInstance from '../utils/axiosInstance';

const Strategy = () => {
  const [performanceData, setPerformanceData] = useState(null);
  const [selectedModel, setSelectedModel] = useState();
  const [selectedProjectId, setSelectedProjectId] = useState();
  // const [dashboardState, setDashboardState] = useState({});
  // const { tokenBalance } = useWalletContext();
  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  // const { disconnect } = useDisconnect();
  const handleDataFetched = useCallback((data) => {
    setPerformanceData(data);
  }, []);

  const handleModelProjectChange = useCallback((model, projectId) => {
    setSelectedModel(model);
    setSelectedProjectId(projectId);
  }, []);

  // useEffect(() => {
  //   const fetchDashboardState = async () => {
  //     try {
  //       const response = await axiosInstance.get('/dashboard_state');
  //       const result = response.data;
  //       setDashboardState(result);
  //     } catch (error) {
  //       // send({ type: 'VERIFY_TOKEN' });
  //       if (error.response && error.response.status === 401) {
  //         send({ type: "SIGN_OUT" });
  //         localStorage.removeItem("authState");
  //         disconnect();
  //       }
  //       console.error('Error fetching dashboard state:', error);
  //     }
  //   };

  //   isConnected &&fetchDashboardState();
  // }, [state,send,isConnected]);

  const renderComponents = (data) => (
    <>
      <HistoricalOverview
        onDataFetched={data ? handleDataFetched : null}
        selectedModel={data ? selectedModel : null}
        selectedProjectId={data ? selectedProjectId : null}
        onModelProjectChange={data ? handleModelProjectChange : null}
        dashboardState={data ? state.context.dashboardState : null}
      />
      <StrategyOverview
        performanceData={data ? performanceData : null}
        selectedModel={data ? selectedModel : null}
        selectedProjectId={data ? selectedProjectId : null}
        dashboardState={data ? state.context.dashboardState : null}
      />
      <Trades
        dashboardState={data ? state.context.dashboardState : null}
        selectedModel={data ? selectedModel : null}
        selectedProjectId={data ? selectedProjectId : null}
      />
    </>
  );

  return <>{isConnected ? renderComponents(state.context.dashboardState) : renderComponents(null)}</>;
};


export default withLayout(withAuthenticationGuard(Strategy));