// // src/HOC/withLayout/withLayout.js
// import React from 'react';
// import styles from './Layout.module.css';
// import Sidebar from '../../components/Sidebar/Sidebar.js';
// import Header from '../../components/Header/Header.js';

// const withLayout = (WrappedComponent) => {
//   return (props) => (
//     <div className={styles.mainContainer}>
//       <div className={styles.subContainer}>
//         <div className={styles.leftContainer}>
//           <Sidebar />
//         </div>
//         <div className={styles.rightContainer}>
//           <div className={styles.border}>
//             <div className={styles.rightTopContainer}>
//               <Header />
//             </div>
//             <div className={styles.gradient}>
//               <div className={styles.rightBottomContainer}>
//                 <WrappedComponent {...props} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default withLayout;

import React, { useState, useEffect } from "react";
import styles from "./Layout.module.css";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import Header from "../../components/Header/Header.js";
import MinimizeIcon from "../Icons/MinimizeIcon.js";
import MaximizeIcon from "../Icons/MaximizeIcon.js";
import ConnectModal from "../ConnectModal/ConnectModal.jsx";
import HamburgerIcon from "../Icons/HamburgerIcon.js";
import { useNavigate } from "react-router-dom";

const withLayout = (WrappedComponent) => {
  // Create a new functional component
// const withLayout = (WrappedComponent) => {
  const WithLayout = (props) => {
    const [fullWidth, setFullWidth] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(
      window.innerWidth > 1100
    );

    const [sidebarToggled, setSidebarToggled] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 1100 && !sidebarToggled) {
          setIsSidebarVisible(true);
        } else if (window.innerWidth <= 1100 && !sidebarToggled) {
          setIsSidebarVisible(false);
        }
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [sidebarToggled]);

    const navigate = useNavigate();

    const toggleSidebar = () => {
      setIsSidebarVisible((prev) => !prev);
      setSidebarToggled(true);
    };

    const toggleWidth = () => {
      setFullWidth((prev) => !prev);
    };

    const handleLogoClick = () => {
      navigate("/");
    };

    if (fullWidth) {
      return (
        <>
          <div className={styles.fullWidthRightBottomContainer}>
            <WrappedComponent {...props} />
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={toggleWidth}>
              {fullWidth ? <MinimizeIcon /> : <MaximizeIcon />}
            </button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.hamburger} onClick={toggleSidebar}>
          <HamburgerIcon />
        </div>

        <div className={styles.mainContainer}>
          <div className={styles.subContainer}>
            <div
              className={`${styles.leftContainer} ${
                isSidebarVisible ? styles.visible : ""
              }`}
            >
              {" "}
              <Sidebar onClick={handleLogoClick} />
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.logo} onClick={handleLogoClick}>
                <img src="images/yaiLogo.svg" alt="Logo" />
              </div>
              <div className={styles.border}>
                <div className={styles.rightTopContainer}>
                  <Header />
                </div>
                <div className={styles.gradient}>
                  {/* <ConnectModal/> */}
                  
                  <div className={styles.rightBottomContainer}>
                    <WrappedComponent {...props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={toggleWidth}>
              {fullWidth ? <MinimizeIcon /> : <MaximizeIcon />}
            </button>
          </div>
        </div>
      </>
    );
  };

  // Set the display name for the HOC
  WithLayout.displayName = `WithLayout(${getDisplayName(WrappedComponent)})`;

  return WithLayout;
};

// Helper function to get the display name of a component
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withLayout;
