import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount
} from "@web3modal/ethers/react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import { useWalletContext } from "../../context/WalletContext.js";
import styles from "./WalletButtons.module.css";

const WalletButtons = () => {
  const { open } = useWeb3Modal();
  // const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();
  const [balance, setBalance] = useState(null);
  // const [tokenBalance, setTokenBalance] = useState(null);
  // const [tokenSymbol, setTokenSymbol] = useState("");
  const { handleWalletBalance } = useWalletContext();
  const { state, send } = useContext(AuthContext);

  useEffect(() => {
    handleWalletBalance(balance);
  }, [balance]);

  return (
    <>
      <div className={styles.buttonWallet} onClick={() => open()}>
        <img src="/images/walletIcon.svg" alt="Wallet" />
        Wallet
      </div>
      <div
        className={styles.buttonLogOut}
        onClick={() => {
          send({ type: "SIGN_OUT" });
          // localStorage.removeItem("authState");
          // disconnect();
        }}
      >
        <img src="/images/logoutIcon.svg" alt="Log Out" />
        Log Out
      </div>
    </>
  );
};

export default WalletButtons;
