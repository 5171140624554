import { setup, assign, fromPromise } from "xstate";
import axiosInstance from "../utils/axiosInstance";
import {extractProjectIds} from "../utils/helpers"
import { PH_GENERAL_OVERVIEW } from "../constants/constants";
export const authMachine = setup({
  types: {},
  guards:{
    isAccessTokenExist:({context})=>{
      return !!context.accessToken
    }
  }
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgYgKoAUARAQQBUBRAfXwCUB5AKXIGFTKBJQgbQAYBdRKAAOAe1gBLdOJEA7QSAAeiAJzKArADoAjOoDMugCwAOZQCYDy3QBoQAT0RaeJjUbXKLJo0a1a1RgL7+NmhY2ADK7ADiAHKUdLikvAJIIKISUrLySghqAOw29gi5vtpqAGymJhYGPDyGgcEYmBriEAA2YOFRsezRSfJpktJyKdlaplpGGrpqvkZOs7lORgWIxWUupqbqZVplPGVLpg0gIc2tHdj9KYMZI6BjE1MzcwtaS16rCCa6024Gk1MuU8uTUJzOGgkUBk4hkUHYMmwEFkYBaMgAbiIANaoiFQmFwhEIWGYgDGyDuSWuwjEQ0yowcZV0PA0llBlkcRjKTOsdjWtQ0BjUukcW12QtMZXBTUh4mhsPhiLAACdlSJlRohG0KQAzdUAWw0eLlBMVxIxInJlP41NStLuWUZzNZunZIqc3P0X32G1Uql2tQq+mU0qwRqaYBkUitkCu-AG9uGjoQe2dbLc7q5PK+jh8Ln+QsBdTqoeabREyAgCsIyFgmAARhXlRAwugKZ1kTJUSTsbiZeXK9Xaw2my22+gwOayRThlT4zdE-SHoh9AYNMCtCKDDMzKC6l8nqVXZK1FtKoYAkFTv2K1W4TW643kM3W+3sCq1RqtbqDeGwwO7ygB8R2fMd2ynS0Z1kOdkhpdIkwZBBV3Xbwtx3IE1H3Plvi0QU-VyQtZl0SopSvCEAKHR9R1fCcuhiOIEltW4EOXJDDBQzctG3NwMKwwp-kFA5mSFYoDFySpSw0Ci4QACREfUwBoEQMDAGs2yRFE0UxHE-zLW8FTkhSlJUtTkAgq1Zxtec4Lpe5FEQbxcmmcYKlMcpylyZQvmZX4fSMYS9ksWowTIm9B1k+TFOUidTPfVV1U1bV0D1ZVDXI-SIqM6LVIpMyews6CrNgu14KXeycKckVJS2dzDi87DTAFPdagsRrxLMY5QrDWBUFJUk4FgPAiDIKgAFk6EIcgABlKDCUgRqYxc7OyRznOqtyyg8+rCjc35HDKLkeDySYDtyUjGjDD91WwBRYHHXEdQnZUAApGtqABKbAISu5VFtK5aHCWDYeCWAxTBecxwd5fi3A0TDaiO95NyMXJJPLKAoAVOgMA0rstN7XSpJEDGsYwcyoJkGCE3+5NVqq1zas871T0FPZDm2fRPEvC69JJuFsfQOLP0Sn9UsJ9HMf5sn8opqmFxpxC6ZcmrNrqr4DBqDQ3NqIFduUJlSKvGQRAgOB5DOanbOTbbEAAWmBhHHadvJJIuMBLYdRDHDUNddGUIxtxmJxzGhxAymUXDiO2IjlDqGYDEk-EFQRD2WPKri9nXPII-B5RgTzrRvXmTZtm3PZQWBbnrzDM5I2jdsIFTsqxlcZRpm3bx9eBTC8i+VQnP9jXfH12P3nO6u9PCoDhyfF97qbgGcnybC1EwrXym2HR1Dc1GusnwDDKikzcoX5M8k0YUuO2CojGqm2EHBll9tcPweF8QweATvfIV6-rYHgeWVsvZHV9oPfQmFb7bm8o1DQjsZjESZBtSSP1T5e1bu3XQndDiuCOsvfixEXA8H9oPM6B19Zo2JpLKAAtUGsT8JobkxZXAWBmCjb0uQnIg0qAHAOkw9ghUCEAA */
  id: "auth",
  initial: "idle",
  context: {
    accessToken: null,
    ip_address: null,
    wallet_address: null,
    signature: null,
    error: null,
    dashboardState: null ,
    modelState:[],
    projectIds:[],
    seletedProjectId:null,
    selectedOption:null,
  },
  on: {
    UPDATE_PROJECT_ID: {
      actions: [assign({
        selectedProjectId: ({ event }) => event.payload.selectedProjectId
      })]
    },
    SIGN_OUT: {
      target: ".loggingOut",
      reenter: true,
    },
  },
  states: {
   
    idle: {
      entry: [
        assign(({ context }) => {
          const val = JSON.parse(localStorage.getItem("authState"));
          return { ...context, ...val };
        }),
      ],
      always:[{target:"authenticated",guard:"isAccessTokenExist"}],
      on: {
        SIGN_IN: "signingIn",
      },
    },

    signingIn: {
      entry: [
        ({ context, event }) => {
          localStorage.setItem(
            "authState",
            JSON.stringify({
              ...context,
              ...event.payload,
            })
          );
        },
      ],
      invoke: {
        src: "signInService",
        input: ({ event, self }) => ({
          ip_address: event.payload.ip_address,
          wallet_address: event.payload.wallet_address,
          signature: event.payload.signature,
          nonce: event.payload.nonce,
          self,
        }),
        onDone: {
          target: "authenticated",
          actions: [
            assign({
              accessToken: ({ event }) => event.output.accessToken,
              ip_address: ({ event }) => event.output.ip_address,
              wallet_address: ({ event }) => event.output.wallet_address,
              signature: ({ event }) => event.output.signature,
              error: null,
            }),
            ({ context }) => {
              localStorage.setItem("authState", JSON.stringify(context));
            },
          ],
        },
        onError: {
          target: "error",

          actions: assign({
            error: ({ event }) => event.error,
            accessToken: null,
          }),

          reenter: true,
        },
      },
    },

    authenticated:{
    
      always:[
        {target:"loadingDashboardState", guard:"isAccessTokenExist"}
      ]
    },

    loadingDashboardState: {
      entry: [
        ({ context }) => {
          localStorage.setItem("authState", JSON.stringify(context));
        },
      ],
      invoke: [
        {
          src: 'fetchDashboardState',
          input:({context, event})=>({context,event }),
          onDone: { target: 'loadingHomeRouteData', actions: [assign(({context,event})=>{
            return{
              dashboardState:event.output.result
            }
          }), assign(({context,event})=>{
            const uniqueProjectIds = extractProjectIds(event.output.result);


            return {
              projectIds:uniqueProjectIds,
                        }

          })] },
          onError: { target: 'error' }
        },
      ],
      on: {
        SIGN_OUT: {
          target: "#auth.loggingOut",
          reenter: true,
        },
      },
    },


    loadingHomeRouteData:{

      invoke: 
        {
          src: 'fetchModelsData',
          input:({context, event})=>({context,event }),

          onDone: { target:'success', actions: [assign(({event})=>{
            return {
              modelState:event.output.result
            }
            }),
          ]},
        onError: { target: 'error' }
        },
      

    },
    success:{
      on: {
        UPDATE_MODEL_STATE: {target:"loadingHomeRouteData"}
      }
    },

    error: {
      entry: [
        "disconnectFromMetamask",
        assign({
          accessToken: null,
          dashboardState:null,
          modelState:null ,
          projectIds:[]
        }),
        () => {
          console.log("Signed out");
          localStorage.removeItem("authState");
        },
      ],
      after:{
        2000:{
          target:"idle"
        }
      }
    },

    loggingOut: {
      invoke: 
      {
        src: 'logoutService',
        onDone: { target:'idle', actions: [
          "disconnectFromMetamask",
          "clearError",
          assign({
            accessToken: null,
            dashboardState:null,
            modelState:null,
            projectIds:[]
          }),
          () => {
            console.log("Signed out");
            localStorage.removeItem("authState");
          },
      ]},
      onError: { target: 'error' }
      },
    },
  },
});
