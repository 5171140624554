import { ethers } from 'ethers';
import YContractABI from '../abi/erc20.json'; // Make sure this path is correct
import { tokenAddresses } from '../constants/constants.js';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { useMemo } from 'react';
import {  useWeb3ModalAccount } from '@web3modal/ethers/react';
import useIpLookup from '../AuthWrapper/useIpLookup.js'
import { useErrorContext } from "../context/ErrorContext.js";
export const useSigner = () => {
  const { walletProvider } = useWeb3ModalProvider();

  return useMemo(() => {
    if (!walletProvider) {
      return null;
    }

    const provider = new ethers.BrowserProvider(walletProvider);
    return provider;
  }, [walletProvider]);
};

export const getYContract = (signer) => {
  try {
    const yContract = new ethers.Contract(tokenAddresses.Y, YContractABI, signer);
    return yContract;
  } catch (error) {
    console.error('Error loading YAI contract:', error);
    throw error;
  }
};

export const useSignMessage = () => {
  const signer = useSigner();
  const { address } = useWeb3ModalAccount();
  const ipAddress=useIpLookup()
  const { axiosInstance } = useErrorContext();
  const signMessage = async () => {
    if (!signer) {
      throw new Error('Signer not available');
    }

    try {
      // Check if we have cached values

      const response = await axiosInstance.post(`/nonce`, {
        wallet_address: address,
        ip_address: ipAddress,
      });
      let message = `${response.data.nonce}`;
      // Sign the message
      let signature = await (await signer.getSigner()).signMessage(message);

      return { signature };
    } catch (error) {
      console.error('Error signing message:', error);
      throw error;
    }
  };

  return signMessage;
};
