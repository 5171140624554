import { useWeb3ModalAccount, useDisconnect } from "@web3modal/ethers/react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthWrapper/AuthWrapper.js";
import withAuthenticationGuard from "../components/guards/withAuthWrapper";
import withBalanceRestriction from "../components/guards/withBalanceRestriction";
import MarketOverview from "../components/MarketOverviewModel/MarketOverview.js";
import HistoricalOverviewModel from "../components/ModelHistoricalOverview/HistoricalOverview.js";
import ModelOverview from "../components/ModelOverview/ModelOverview.js";
import ModelSentiment from "../components/ModelSentiment/ModelSentiment.js";
import Strategies from "../components/StrategiesModel/Strategies.js";
import withLayout from "../components/withLayout/withLayout.jsx";
import { MinTokenAmount } from "../constants/constants";
import { useWalletContext } from "../context/WalletContext.js";
import { useModelContext } from "../ContextProvider.js";
import axiosInstance from "../utils/axiosInstance.js";

const Model = () => {
  const [dashboardState, setDashboardState] = useState();
  // const { tokenBalance } = useWalletContext();
  const { model, projectID } = useModelContext(); // Use context to get model and project ID
  const { isConnected } = useWeb3ModalAccount();
  const { state, send } = useContext(AuthContext);
  // const { disconnect } = useDisconnect();

  // useEffect(() => {
  //   const fetchDashboardState = async () => {
  //     try {
  //       const authState =JSON.parse(localStorage.getItem("authState"));
  //       if (authState) {
  //         const response = await axiosInstance.get("/dashboard_state");
  //         const result = response.data;
  //         setDashboardState(result);
  //       } 
  //     } catch (error) {
  //       if (error.response && error.response.status === 401) {
  //         send({ type: "SIGN_OUT" });
  //         localStorage.removeItem("authState");
  //         disconnect();
  //       } else {
  //         console.error("An error occurred:", error);
  //       }
  //     }
  //   };

  //   fetchDashboardState();
  // }, [state]);
  
  const renderComponents = (data) => (
    <>
      <HistoricalOverviewModel
        dashboardState={data}
        initialModel={data ? model : null}
        initialProjectId={data ? projectID : null}
      />
      <MarketOverview
        dashboardState={data}
        initialProjectId={data ? projectID : null}
      />
      <ModelSentiment
        dashboardState={data}
        initialProjectId={data ? projectID : null}
      />
      <ModelOverview dashboardState={data} initialModel={data ? model : null} />
      <Strategies
        dashboardState={data}
        initialProjectId={data ? projectID : null}
      />
    </>
  );

  return (
    <>
      {isConnected  ? (
        renderComponents(state.context.dashboardState)
      ) :(
        <>{renderComponents(null)}</>
      )}
    </>
  );
};

export default withLayout(
  withAuthenticationGuard(Model)
);