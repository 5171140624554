import { useDisconnect } from '@web3modal/ethers/react';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthWrapper/AuthWrapper';
import { MARKET_OVERVIEW_INIT, TRADES_STRATEGY } from '../../constants/constants';
import { useErrorContext } from '../../context/ErrorContext';
import Tooltip from '../Tooltip/Tooltip';
import styles from './Trades.module.css';

function Trades({ dashboardState, selectedModel, selectedProjectId }) {
  const [models, setModels] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableClass, setTableClass] = useState("");
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const [data, setData] = useState(MARKET_OVERVIEW_INIT);
  const { axiosInstance } = useErrorContext();
  const [sortConfig, setSortConfig] = useState({
    key: "timestamp",
    direction: "desc",
  });
  // const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (dashboardState) {
      const collections = Object.keys(dashboardState);
      setModels(collections);
      if (collections.length > 0) {
        const initialModel = collections[0];
        setProjectIds(dashboardState[initialModel] || []);
      }
    } else {
      setModels([]);
      setProjectIds([]);

      setData(MARKET_OVERVIEW_INIT);
    }
  }, [dashboardState]);

  useEffect(() => {

    console.log("this is dashboardState >>>>>>>",selectedModel && selectedProjectId,selectedModel , selectedProjectId)
    const fetchData = async () => {

      setIsLoading(true);
      try {
        const response = await axiosInstance.get("/strategy_history", {
          params: {
            model: selectedModel,
            project_id: selectedProjectId,
            // start_date: '2023-09-07',
            // threshold: 1.5,
            // size: 0.05,
          },
        });
        const result = response.data;
        sortData('timestamp', 'desc', result.trades);
        setData(result.trades);

        sortData("timestamp", "desc", result.trades);
        setData(result.trades);

        setTableClass(result.trades.length > 10 ? styles.largeTable : "");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          send({ type: "SIGN_OUT" });
          localStorage.removeItem("authState");
          disconnect();
        }
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedModel && selectedProjectId) fetchData();
  }, [selectedModel, selectedProjectId]);

  const sortData = (key, direction = "desc", dataToSort = data) => {
    const sortedData = [...dataToSort].sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const toggleSortDirection = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }
    sortData(key, direction);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort ascending"
          className={styles.sortIcon}
        />
      ) : (
        <img
          src="/images/tableSortArrow.svg"
          alt="Sort descending"
          className={`${styles.sortIcon} ${styles.inverted}`}
        />
      );
    }
    return (
      <img
        src="/images/tableSortArrow.svg"
        alt="Sort"
        className={`${styles.sortIcon} ${styles.inactive}`}
      />
    );
  };

  const columns = TRADES_STRATEGY;

  const formatValue = (key, value) => {
    if (value === "--" || value === null || value === undefined) return "--";

    if (key === "timestamp") {
      return new Date(value).toLocaleString();
    }

    if (key === "price") {
      return `$${value.toFixed(2)}`;
    }

    if (key === "position_change") {
      return `${value.toFixed(2)}%`;
    }

    return value;
  };

  const getSideClass = (positionChange) => {
    return positionChange > 0 ? styles.buy : styles.sell;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Trades</div>
            <Tooltip id="trades" page="strategy-page" />
          </div>
        </div>
        <div className={`${styles.tableContainer} ${tableClass}`}>
          <table className={styles.table}>
            <thead>
              <tr>
                {columns.map(({ key, label, sortable }) => (
                  <th
                    key={key}
                    onClick={
                      sortable ? () => toggleSortDirection(key) : undefined
                    }
                    aria-sort={
                      sortConfig.key === key ? sortConfig.direction : "none"
                    }
                  >
                    {label} {sortable && getSortIcon(key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className={styles.tableRow}>
                  {columns.map(({ key }) => (
                    <td
                      key={key}
                      className={
                        key === "position_change" ? getSideClass(item[key]) : ""
                      }
                    >
                      {key === "side" ? (
                        item.position_change === "--" ||
                        item.position_change === null ||
                        item.position_change === undefined ? (
                          "--"
                        ) : (
                          <span className={getSideClass(item.position_change)}>
                            {item.position_change > 0 ? "BUY" : "SELL"}
                          </span>
                        )
                      ) : (
                        formatValue(key, item[key])
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Trades;
