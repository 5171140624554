import React from "react";
import Plot from "react-plotly.js";

function PerformanceChart({ data }) {
  // Extract data from props
  const timeSeries = data?.close_timestamps; // Hourly timestamps
  const close = data?.close;                 // Hourly close prices
  const modelTimestamps = data?.model_timestamps; // Daily timestamps
  const predictedPricesData = data?.predicted_price; // Daily predicted prices

  // Helper function to extract 'YYYY-MM-DD' from ISO timestamp
  const getDateKey = (timestamp) => new Date(timestamp).toISOString().split('T')[0];

  // Check if the input data arrays are defined and are arrays
  if (!Array.isArray(modelTimestamps) || !Array.isArray(predictedPricesData)) {
    console.error("modelTimestamps or predictedPricesData is not an array");
    return null; // or provide a fallback UI
  }

  if (!Array.isArray(timeSeries) || !Array.isArray(close)) {
    console.error("timeSeries or close is not an array");
    return null; // or provide a fallback UI
  }

  // Create a map from 'YYYY-MM-DD' to predicted_price
  const predictedPriceMap = new Map(
    modelTimestamps.map((timestamp, index) => [
      getDateKey(timestamp),
      predictedPricesData[index],
    ])
  );

  const filledPredictedPrices = [];
  let lastPredictedPrice = null;
  let lastPredictionTimestamp = null;

  // Forward fill the predicted prices for each hourly timestamp based on date
  timeSeries.forEach((timestamp) => {
    const currentDate = new Date(timestamp);
    const dateKey = getDateKey(timestamp);
    const currentPredictedPrice = predictedPriceMap.get(dateKey);
    
    if (currentPredictedPrice !== undefined) {
      const predictionTimestamp = new Date(modelTimestamps[predictedPricesData.indexOf(currentPredictedPrice)]);
      if (currentDate >= predictionTimestamp) {
        lastPredictedPrice = currentPredictedPrice;
        lastPredictionTimestamp = predictionTimestamp;
      }
    }
    
    if (lastPredictionTimestamp && currentDate >= lastPredictionTimestamp) {
      filledPredictedPrices.push(lastPredictedPrice);
    } else {
      filledPredictedPrices.push(null);
    }
  });

  // Calculate residuals based on (predictedPrice - close) / close
  const residuals = timeSeries.map((_, index) => {
    const predPrice = filledPredictedPrices[index];
    const currentClose = close[index];
    return predPrice !== null && currentClose ? (predPrice - currentClose) / currentClose : 0;
  });

  // Colorscale for residual bars
  const CustomLightRedYellowGreen = [
    [0, "rgba(255,99,71,0.8)"],  // Tomato Red
    [0.1, "rgba(255,140,0,0.8)"],// Dark Orange
    [0.2, "rgba(255,165,0,0.8)"],// Orange
    [0.3, "rgba(255,215,0,0.8)"],// Gold
    [0.4, "rgba(255,255,0,0.8)"],// Yellow
    [0.5, "rgba(255,255,102,0.8)"],// Light Yellow
    [0.6, "rgba(173,255,47,0.8)"],// Green-Yellow
    [0.7, "rgba(144,238,144,0.8)"],// Light Green
    [0.8, "rgba(50,205,50,0.8)"],// Lime Green
    [0.9, "rgba(34,139,34,0.8)"],// Forest Green
    [1, "rgba(0,128,0,0.8)"],    // Green
  ];

  // Ensure residuals array has valid numerical values
  const residualsMin = Math.min(...residuals);
  const residualsMax = Math.max(...residuals);

  if (Number.isNaN(residualsMin) || Number.isNaN(residualsMax)) {
    console.error("Residuals contain invalid values");
    return null; // or provide a fallback UI
  }

  // Debug logs to verify data
  console.log("Filled Predicted Prices:", filledPredictedPrices);
  console.log("Residuals:", residuals);

  // Define Plotly traces
  const traces = [
    {
      x: timeSeries,
      y: close,
      type: "scatter",
      mode: "lines",
      name: "Actual Price",
      line: { color: "#FFFFFF", width: 2 }, // White for actual price
      fill: "none",
      hovertemplate: "Date: %{x}<br>Actual: %{y}<extra></extra>", // Removes trace name from hover
      showlegend: true,
    },
    {
      x: timeSeries,
      y: filledPredictedPrices,
      type: "scatter",
      mode: "lines",
      name: "Predicted Price",
      line: { color: "rgba(255,99,71,0.8)", width: 1 }, // Tomato Red for predicted price
      fill: "none",
      hovertemplate: "Date: %{x}<br>Predicted: %{y}<extra></extra>", // Removes trace name from hover
      showlegend: true,
    },
    {
      x: timeSeries,
      y: residuals,
      type: "bar",
      name: "Residuals",
      marker: {
        color: residuals,
        colorscale: CustomLightRedYellowGreen,
        cmin: residualsMin,
        cmax: residualsMax,
        colorbar: {
          title: "Residuals",
          titleside: "right",
        },
      },
      yaxis: "y2",
      hovertemplate: "Date: %{x}<br>Residual: %{y:.2%}<extra></extra>", // Shows residual as percentage
      showlegend: false, // No legend for residual bars
    },
  ];

  const layout = {
    paper_bgcolor: "rgba(18, 18, 18, 0.1)", // Semi-transparent background
    plot_bgcolor: "rgba(18, 18, 18, 0.1)", // Semi-transparent background

    xaxis: {
      domain: [0, 1],
      type: "date",
      range:
        timeSeries?.length > 0
          ? [timeSeries[0], timeSeries[timeSeries.length - 1]]
          : [
              new Date().toISOString().substring(0, 12),
              new Date().toISOString().substring(0,13),
            ],
      color: "#FFFFFF",
      anchor: "y2",
      gridcolor: "transparent", // Subtle gridline color
      gridwidth: 0.25, // Thinner gridlines
    },
    yaxis: {
      title: "Price",
      domain: [0.3, 1],
      color: "#FFFFFF",
      gridcolor: "#444444", // Subtle gridline color
      gridwidth: 0.25, // Thinner gridlines
    },
    yaxis2: {
      title: "Residuals",
      tickformat: ".2%",
      domain: [0, 0.25],
      anchor: "x",
      color: "#FFFFFF",
      gridcolor: "transparent", // Subtle gridline color
      gridwidth: 0.25, // Thinner gridlines
    },
    legend: {
      orientation: "h",
      y: 1,
      x: 0.8,
      xanchor: "center",
      yanchor: "bottom",
      font: { color: "#FFFFFF" },
      bgcolor: "rgba(18, 18, 18, 0.5)", // Semi-transparent background (50% opacity)
      bordercolor: "rgba(51, 51, 51, 0.3)", // Lighter and more transparent border (30% opacity)
      borderwidth: 1, // Optional: You can reduce the border width to make it even more subtle
    },
    grid: { rows: 2, columns: 1, pattern: "independent" },
    height: 440,
    font: { color: "#FFFFFF" },
    showlegend: true, // Show legend
    margin: {
      l: 100, // left margin
      r: 100, // right margin
      t: 50, // top margin
      b: 100, // bottom margin
    },
  };
  const config = {
    responsive: true, // Allow chart to be responsive
    displayModeBar: true, // Show the modebar
    modeBarButtonsToRemove: [
      "select2d", // Removes 2D selection
      "lasso2d", // Removes lasso tool
      "zoomIn2d", // Removes zoom in
      "zoomOut2d", // Removes zoom out
      "toImage",
      "resetScale2d", // Removes reset scale button

    ],
    displaylogo: false, // Remove the Plotly logo from the modebar
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Plot
        data={traces}
        layout={layout}
        style={{ width: "100%", height: "100%" }}
        config={config} // Use the config object for interactive tools
      />
    </div>
  );
}

export default PerformanceChart;