import React, { useState, useEffect,useContext ,useMemo } from 'react';
import CustomGauge from './Gauge.js';
import styles from './Technicals.module.css';
import Tooltip from '../Tooltip/Tooltip';
import { BASE_URL } from '../../constants/constants';
import axiosInstance from '../../utils/axiosInstance';
import { AuthContext } from '../../AuthWrapper/AuthWrapper.js';
import { extractProjectIds } from '../../utils/helpers.js';

function Technicals({ dashboardState }) {

  const initialSelectedProjectId = useMemo(() => {
    if (!dashboardState) return null;
    const projectIds = extractProjectIds(dashboardState);
    return projectIds.length > 0 ? projectIds[0] : null;
  }, [dashboardState]);
  
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { state, send } = useContext(AuthContext);

  const residuals = useMemo(() => {
    if (!dashboardState || !state.context?.modelState?.oracle) return {};
    
    const projectId = extractProjectIds(dashboardState)[0];
    return state.context.modelState.oracle[selectedProjectId ?? projectId] || {};
  }, [dashboardState, state.context?.modelState?.oracle, selectedProjectId]);


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectProjectId = (projectId) => {
    send({
      type:"UPDATE_MODEL_STATE",
    })
    setSelectedProjectId(projectId);
    setIsOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.heading}>Technicals</div>
          <Tooltip id="technicals" page="home-page" />
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdown} onClick={toggleDropdown}>
          <span className={styles.selectedOption}>{selectedProjectId ?? initialSelectedProjectId}</span>
            <span className={styles.arrow}>
              {isOpen ? (
                <img
                  className={styles.invertedArrow}
                  src="/images/arrowDown.svg"
                  alt="arrow down"
                />
              ) : (
                <img src="/images/arrowDown.svg" alt="arrow down" />
              )}
            </span>
          </div>
          {isOpen && (
            <div className={styles.dropdownMenu}>
              {state.context?.projectIds.map((projectId) => (
                <div
                  key={projectId}
                  className={styles.dropdownItem}
                  onClick={() => selectProjectId(projectId)}
                >
                  {projectId}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.gaugeContainer}>
        <img
          className={styles.mask}
          src="/images/technicalsMask.svg"
          alt="mask"
        />
        <CustomGauge residual={residuals} />
      </div>
    </div>
  );
}

export default Technicals;
