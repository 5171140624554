import React, { useState, useEffect, useCallback, useContext } from "react";
import PerformanceChart from "./PerformanceChart";
import styles from "./HistoricalOverview.module.css";
import Loader from "../Loader/Loader.js";
import Tooltip from "../Tooltip/Tooltip.js";
import { BASE_URL } from "../../constants/constants";
import { useDisconnect } from "@web3modal/ethers/react";
import { AuthContext } from "../../AuthWrapper/AuthWrapper.js";
import { useErrorContext } from "../../context/ErrorContext.js";

function HistoricalOverview({
  onDataFetched,
  selectedModel,
  selectedProjectId,
  onModelProjectChange,
  dashboardState,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [data, setData] = useState();
  const [models, setModels] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const { disconnect } = useDisconnect();
  const { state, send } = useContext(AuthContext);
  const { axiosInstance } = useErrorContext();

  useEffect(() => {
    if (dashboardState) {
      setModels(Object.keys(dashboardState));
      setProjectIds(dashboardState[selectedModel] || []);
    }
  }, [dashboardState, selectedModel]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("/strategy_history", {
        params: {
          model: selectedModel,
          project_id: selectedProjectId,
        },
      });
      const result = response.data;

      setData(result);
      onDataFetched(result.performance);
      setIsLoading(false);
    } catch (error) {
      
        if (error.response && error.response.status === 401) {
        send({ type: "SIGN_OUT" });
        localStorage.removeItem("authState");
        disconnect();
      } else {
        console.error("Error fetching data:", error);
      }
    }
    // finally {
    //   setIsLoading(false);
    // }
  }, [selectedModel, selectedProjectId, onDataFetched]);

  useEffect(() => {
    if (selectedModel && selectedProjectId && dashboardState) {
      fetchData();
    }
  }, [dashboardState,fetchData, selectedModel, selectedProjectId]);

  // useEffect(() => {
  //   if (
  //     models.length > 0 &&
  //     !models.includes(selectedModel) &&
  //     typeof onModelProjectChange === "function"
  //   ) {
  //     onModelProjectChange(models[0], projectIds[0]);
  //   }
  // }, [models, selectedModel, projectIds, onModelProjectChange]);

  useEffect(() => {
    if (typeof onModelProjectChange === "function") {
      const currentProjectId = projectIds.includes(selectedProjectId)
        ? selectedProjectId
        : projectIds[0];
      if (models.length > 0 && !models.includes(selectedModel)) {
        // If the selected model is not in the list, select the first model and project
        onModelProjectChange(models[0], currentProjectId);
      } else if (
        selectedModel &&
        (!selectedProjectId || !projectIds.includes(selectedProjectId))
      ) {
        // If a model is selected but no project ID is selected (or the selected project ID is invalid),
        // keep the current model and select the first project for that model
        onModelProjectChange(selectedModel, currentProjectId);
      }
    }
  }, [
    models,
    selectedModel,
    projectIds,
    selectedProjectId,
    onModelProjectChange,
  ]);


  const toggleProjectDropdown = () =>
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
  const toggleModelDropdown = () =>
    setIsModelDropdownOpen(!isModelDropdownOpen);

  const selectProjectId = (projectId) => {
    onModelProjectChange(selectedModel, projectId);
    setIsProjectDropdownOpen(false);
  };
  
  const selectModel = (model) => {
    const currentProjectId = projectIds.includes(selectedProjectId)
      ? selectedProjectId
      : projectIds[0];
    onModelProjectChange(model, currentProjectId);
    setIsModelDropdownOpen(false);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.overviewHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>Historical Overview</div>
          <Tooltip id="historical-overview" page="strategy-page" />
        </div>
        <div className={styles.mainDropdownContainer}>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleModelDropdown}>
              <span className={styles.selectedOption}>{selectedModel}</span>
              <span className={styles.arrow}>
                {isModelDropdownOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isModelDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {models?.map((model) => (
                  <div
                    key={model}
                    className={styles.dropdownItem}
                    onClick={() => selectModel(model)}
                  >
                    {model}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown} onClick={toggleProjectDropdown}>
              <span className={styles.selectedOption}>
                {/* <img src={`/images/${selectedProjectId?.toLowerCase()}.svg`} alt={selectedProjectId} /> */}
                {selectedProjectId}
              </span>
              <span className={styles.arrow}>
                {isProjectDropdownOpen ? (
                  <img
                    className={styles.invertedArrow}
                    src="/images/arrowDown.svg"
                    alt="arrow down"
                  />
                ) : (
                  <img src="/images/arrowDown.svg" alt="arrow down" />
                )}
              </span>
            </div>
            {isProjectDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {projectIds?.map((projectId) => (
                  <div
                    key={projectId}
                    className={styles.dropdownItem}
                    onClick={() => selectProjectId(projectId)}
                  >
                    {/* <img src={`/images/${projectId?.toLowerCase()}.svg`} alt={projectId} /> */}
                    {projectId}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartBG}>
          <img src="images/chartBG.png" />
        </div>
        {isLoading && <Loader />}

        <PerformanceChart data={data} />
      </div>
    </div>
  );
}

export default HistoricalOverview;